<template>
  <TosListVuetify serviceName="authentication" :entityName="entityName" :headers="headers" :prepareItemFunction="prepareItems">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Username" v-model="slotProps.data.username" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content" :key="componentKey" >
        <v-row>
          <v-col cols="4">
            <v-text-field label="Username *" v-model="slotProps.data.username" :rules="[rules.required]" :error-messages="slotProps.errors.username" />
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Password"
              :type="fieldTypes.password"
              v-model="slotProps.data.password"
              :error-messages="slotProps.errors.password"
              value=""
              @focus="handleType"
              @blur="handleType"
              autocomplete="off"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Conferma Password"
              :type="fieldTypes.password"
              v-model="slotProps.data.passwordConfirm"
              :error-messages="slotProps.errors.passwordConfirm"
              value=""
            />
          </v-col>
        </v-row>
        <v-expansion-panels multiple v-model="panels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>{{ tLabel("ruolo") }}</span></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="2" v-for="ruolo in listRuoli" :key="ruolo.value">
                  <v-checkbox :label="ruolo.text" :value="ruolo.value" v-model="slotProps.data.listRuoli" @change="componentKey+=1"/>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Utenti",
  listRuoli: [],
  data() {
    return {
      columns: null,
      entityName: "utenti",
      panels: [0],
      fieldTypes: {
        password: "text",
      },
      headers: [
        { text: "", value: "action" },
        { text: "Username", value: "username" },
        { text: "ruolo", value: "listaRuoliStringa", sortable: false },
      ],
      componentKey: 0,
    };
  },
  created() {},
  components: {
    TosListVuetify,
  },
  mixins: [FormatsMixins],
  methods: {
    validate(item) {
      let errors = {};
      const required = "username,password";
      required.split(",").forEach(t => {
        const v = item[t];
        if (v == undefined || v == "") errors[t] = "Campo obbligatorio";
      });
      return errors;
    },
    prepareItems(item) {
      if (item.listRuoli == null) item.listRuoli = [];
    },
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes["password"] = "text";
        console.log("TEXT ");
      } else {
        this.fieldTypes["password"] = "password";
        console.log("PASS");
      }
    },
  },
  async mounted() {
    this.listRuoli = (await this.$api.get(this.$apiConfiguration.BASE_PATH_AUTHENTICATION + "utenti/listRuoli")).sort((a,b)=>a.value-b.value);
  },
};
</script>
